'use client';

import { useSyncExternalStore } from 'react';

import { type Toast, toastStore } from './store';

type ToastOptions = Omit<Omit<Toast, 'variant'>, 'id'>;

export default function useToast() {
  const allToasts = useSyncExternalStore(
    toastStore.subscribe,
    toastStore.getToasts,
    toastStore.getServerSideToasts,
  );
  return allToasts;
}

export const toast = {
  success(opts: ToastOptions) {
    const id = window.crypto.randomUUID();
    toastStore.enqueueToast({
      variant: 'success',
      id,
      ...opts,
    });
    return id;
  },
  error(opts: ToastOptions) {
    const id = window.crypto.randomUUID();
    toastStore.enqueueToast({
      variant: 'error',
      id,
      ...opts,
    });
    return id;
  },
  default(opts: ToastOptions) {
    const id = window.crypto.randomUUID();
    toastStore.enqueueToast({
      variant: 'default',
      id,
      ...opts,
    });
    return id;
  },
  dismiss(id: string) {
    toastStore.dismissToast(id);
  },
};
