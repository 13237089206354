'use client';

import { useUser } from '@auth0/nextjs-auth0/client';
import { usePathname, useRouter } from 'next/navigation';
import { useEffect, useMemo } from 'react';

import { toast } from '@/components/Toast';
import { checkAccessToken } from '@/lib/api/settings';
import checkRestrictedRoute from '@/utils/checkRestrictedRoute';

export default function useAuth() {
  const { user, error, isLoading } = useUser();
  const { push } = useRouter();
  const pathname = usePathname();

  // Redirects if user is on restricted page without valid auth
  useEffect(() => {
    if (isLoading) return;

    // @ts-expect-error Need to append a window variable as useUser is used potentially hundreds of times. Feel free to convert this into a Context in the future.
    if (!window.checkedAccessToken) {
      checkAccessToken().then((isAccessTokenValid) => {
        if (
          (!user || error || !isAccessTokenValid) &&
          checkRestrictedRoute(pathname)
        ) {
          toast.error({
            title: 'Session Expired!',
            description: 'Please log in again.',
          });
          push('/auth');
        }
        // @ts-expect-error Read Above
        window.checkedAccessToken = true;
      });
    }
  }, [isLoading, user, pathname, error, push]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.location.pathname !== pathname)
        // @ts-expect-error Need to append a window variable as useUser is used potentially hundreds of times. Feel free to convert this into a Context in the future.
        window.checkedAccessToken = false;
    }
  }, [pathname]);

  return useMemo(() => ({ isLoading, user }), [user, isLoading]);
}
