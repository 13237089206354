const restrictedRoutes = ['/dash/*'];

export default function checkRestrictedRoute(pathname: string) {
  for (let i = 0; i < restrictedRoutes.length; i++) {
    const route = restrictedRoutes[i];
    const regex = new RegExp(
      '^' + route.replace(/\?/g, '.').replace(/\*/g, '.*') + '$',
    );

    if (regex.test(pathname)) return true;
  }

  return false;
}
