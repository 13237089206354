'use client';

type Listener = () => void;
let listeners: Array<Listener> = [];

// STRICTLY CLIENT SIDE. DO NOT USE ON SERVER

export type Toast = {
  variant: 'success' | 'error' | 'default';
  id: string;
  title?: string;
  description?: string;
  action?: React.ReactNode;
};

let toastQueue: Array<Toast> = [];

export const toastStore = {
  enqueueToast(toast: Toast) {
    toastQueue = [...toastQueue, toast];
    listeners.forEach((l) => l());
  },

  dequeueToast() {
    if (toastQueue[0]) {
      toastQueue.shift();
      toastQueue = [...toastQueue];
      listeners.forEach((l) => l());
    }
  },

  dismissToast(id: string) {
    const toast = toastQueue.find((x) => x.id === id);
    if (toast) {
      toastQueue.splice(toastQueue.indexOf(toast), 1);
      toastQueue = [...toastQueue];
      listeners.forEach((l) => l());
    }
  },

  subscribe(listener: Listener) {
    listeners.push(listener);
    return () => {
      listeners = listeners.filter((l) => l !== listener);
    };
  },

  getToasts() {
    return toastQueue;
  },

  getServerSideToasts() {
    return toastQueue;
  },
};
