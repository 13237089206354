'use client';

// TODO: Create Landing Page
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';

import useAuth from '@/hooks/useAuth';

export default function Home() {
  const { user, isLoading } = useAuth();
  const { push } = useRouter();

  useEffect(() => {
    if (!isLoading) {
      push(!user ? '/auth' : '/dash');
    }
  }, [user, isLoading, push]);

  return <div className="bg-bg-light-primary dark:bg-bg-dark-primary" />;
}
